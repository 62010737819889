export const csvMaker = <TData extends object>(data: TData[], skipHeaders: boolean = false): BlobPart => {
  if (!data || data.length === 0) {
    return '';
  }

  if (skipHeaders) {
    const csv = data.map(getParsedDataRow);
    return csv.join('\n');
  }

  const header = getHeaderRow(data[0]);
  const csv = data.map(getParsedDataRow);
  return [header, ...csv].join('\n');
};

const valueParsing = (value: unknown) => {
  if (value instanceof Date) {
    return value.toISOString();
  }
  if (Array.isArray(value)) {
    return JSON.stringify(value);
  }
  if (value === null || value === undefined || typeof value === 'object') {
    return '';
  }
  return String(value);
};

const getParsedDataRow = <TData extends object>(row: TData) => {
  return Object.values(row)
    .map((v) => `"${valueParsing(v).replace(/"/g, '""')}"`)
    .join(',');
};

const getHeaderRow = <TData extends object>(row: TData | undefined) => {
  if (!row) {
    return '';
  }

  return Object.keys(row)
    .map((key) => `"${key.replace(/"/g, '""')}"`)
    .join(',');
};
