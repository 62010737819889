import { useCallback } from 'react';
import { xlsxMaker, download } from '@fjuel/utils';

export const useExportToXlsx = <TData extends object = object>() => {
  return useCallback((data: TData[], fileName: string, skipHeaders: boolean = false) => {
    if (!data.length) {
      return;
    }
    const blob = xlsxMaker(data, skipHeaders);
    download(blob, `${fileName}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  }, []);
};
