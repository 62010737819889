import { ReactNode } from 'react';
import { useDevice } from '@fjuel/pages/Device/DeviceContext.ts';
import { Box, TypographyProps } from '@mui/material';
import { WidgetHeader } from './WidgetHeader.tsx';
import { Export } from '../ExportTable';
import { Exportable } from '@fjuel/schemas/interfaces/Exportable.ts';
import { ExportToCsvProps } from '@fjuel/components/ExportTable/Export.tsx';

interface HeaderProps<T extends Exportable> extends TypographyProps {
  children: ReactNode;
  exportToCsv?: ExportToCsvProps<T>;
}
export const Header = <T extends Exportable>({ children, exportToCsv, ...rest }: HeaderProps<T>) => {
  const { isPhone, isTablet } = useDevice();

  if ((isPhone || isTablet) && exportToCsv) {
    return (
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <WidgetHeader {...rest}>{children}</WidgetHeader>
        </Box>
        <Export table={exportToCsv.table} prefix={exportToCsv.prefix} />
      </Box>
    );
  } else {
    return <WidgetHeader {...rest}>{children}</WidgetHeader>;
  }
};
