import { fjuelApi } from '@fjuel/configs/api';
import { VesselSchema } from '@fjuel/schemas/VesselSchema';
import { createQuery } from 'react-query-kit';
import { z } from 'zod';

const getVessels = async () => {
  const rawFromApi = await fjuelApi.get('vessel');
  const parsedFromApi = await rawFromApi.json();
  try {
    return z.array(VesselSchema).parse(parsedFromApi);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const useVessels = createQuery({
  primaryKey: 'vessels',
  queryFn: getVessels,
  retry: false,
});
