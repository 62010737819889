import { Table } from '@tanstack/react-table';
import { Button, Menu, MenuItem } from '@mui/material';
import { getExportedValues } from '@fjuel/components/ExportTable/getExportedValues.ts';
import { useDevice } from '@fjuel/pages/Device/DeviceContext.ts';
import { Exportable } from '@fjuel/schemas/interfaces/Exportable.ts';
import { useExportToCsv, useExportToXlsx } from '@fjuel/hooks'; // Assuming you have a hook for Excel export
import { dateTimeShortFormatter } from '@fjuel/utils';
import { useCallback, useState } from 'react';

export interface ExportToCsvProps<T extends Exportable> {
  table: Table<T>;
  prefix: string;
}

export const Export = <T extends Exportable>({ table, prefix }: ExportToCsvProps<T>) => {
  const { isPhone, isTablet } = useDevice();
  const height = isPhone || isTablet ? '40px' : '56px';
  const exportToCsv = useExportToCsv();
  const exportToExcel = useExportToXlsx();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleExportCsv = useCallback(() => {
    const fileName = `${prefix}-${dateTimeShortFormatter(new Date())}`;
    exportToCsv(getExportedValues(table), fileName, true);
    handleMenuClose();
  }, [exportToCsv, table, prefix]);

  const handleExportExcel = useCallback(() => {
    const fileName = `${prefix}-${dateTimeShortFormatter(new Date())}`;
    exportToExcel(getExportedValues(table), fileName, true);
    handleMenuClose();
  }, [exportToExcel, table, prefix]);

  return (
    <>
      <Button
        size="large"
        variant="outlined"
        sx={{ whiteSpace: 'nowrap', flex: 'none', height }}
        onClick={handleMenuClick}
      >
        Export
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleExportCsv}>CSV</MenuItem>
        <MenuItem onClick={handleExportExcel}>Excel</MenuItem>
      </Menu>
    </>
  );
};
