import { z } from 'zod';
import { checkDuplicateUpperLimits, EffectMarkupCommonSchema } from './EffectMarkupCommonSchema.ts';

export const EffectMarkupSchema = EffectMarkupCommonSchema.extend({
  id: z.string().uuid().describe('Unique identifier of the effect markup'),
});

export type EffectMarkup = z.infer<typeof EffectMarkupSchema>;

export const EffectMarkupsSchema = z.array(EffectMarkupSchema).superRefine((data, ctx) => {
  checkDuplicateUpperLimits(data, ctx);
});
