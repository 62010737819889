import * as XLSX from 'xlsx';

export const xlsxMaker = <TData extends object>(data: TData[], skipHeaders: boolean = false): BlobPart => {
  const ws = XLSX.utils.json_to_sheet(data, { skipHeader: skipHeaders });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  const binaryString = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' }) as string;

  // Convert binary string to Uint8Array
  const byteNumbers = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    byteNumbers[i] = binaryString.charCodeAt(i);
  }

  return byteNumbers.buffer;
};
