import { useCallback } from 'react';
import { csvMaker, download } from '@fjuel/utils';

export const useExportToCsv = <TData extends object = object>() => {
  return useCallback((data: TData[], fileName: string, skipHeaders: boolean = false) => {
    if (!data.length) {
      return;
    }
    const blob = csvMaker(data, skipHeaders);
    download(blob, `${fileName}.csv`, 'text/csv;charset=utf-8;');
  }, []);
};
